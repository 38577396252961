import { createStore } from 'vuex'

export default createStore({
    state() {
        return {
            // storage默认存储为字符串，转换成原本数据格式
            token: window.sessionStorage.getItem('satoken'),
            autoLogin: window.localStorage.getItem('autoLogin'),
            localToken: window.localStorage.getItem('satoken'),
            companyId:2
        }
    },
    mutations: {
        setCompanyId(state,cid) {
          state.companyId = cid;
        },
        $_setToken(state, value) {
            state.token = value
            sessionStorage.setItem('satoken', value)
        },
        $_setLocalToken(state, value) {
            state.localToken = value
            localStorage.setItem('satoken', value)
        },

        autoLogin: (state, value) => {
            state.autoLogin = value
            if (state.autoLogin) {
                window.localStorage.setItem('satoken', state.localToken)
                window.sessionStorage.setItem('satoken', state.localToken)
            } else {
                window.localStorage.removeItem('satoken')
            }
            window.localStorage.setItem('autoLogin', value);
        },
        logout: (state) => {
            state.localIsLogin = null
            state.autoLogin = false
            window.sessionStorage.clear();
            window.localStorage.clear();
        }
    },
    getters: {
      getcompanyId:state => state.companyId
    },
    actions: {},
    modules: {}
})
