import {
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
    ElMessage,
    ElContainer,
    ElHeader,
    ElAside,
    ElMain,
    ElMenu,
    ElMenuItemGroup,
    ElMenuItem,
    ElSubmenu,
    ElSelect,
    ElOption,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElCard,
    ElRow,
    ElCol,
    ElTable,
    ElTableColumn,
    ElSwitch,
    ElTooltip,
    ElPagination,
    ElTabs,
    ElTabPane,
    ElPopconfirm,
    ElProgress,
    ElConfigProvider,
    ElDialog,
    ElUpload,
    ElTag,
    ElTree,
    ElCheckbox,
    ElRadio,
    ElRadioGroup,
	ElDatePicker
} from 'element-plus'

// import lang from 'element-plus/lib/locale/lang/zh-cn'
// import 'dayjs/locale/zh-cn'
// import locale from 'element-plus/lib/locale'
// locale.use(lang)

export default (app) => {
	app.use(ElDatePicker)
    app.use(ElButton)
    app.use(ElInput)
    app.use(ElForm)
    app.use(ElFormItem)
    app.use(ElContainer)
    app.use(ElHeader)
    app.use(ElAside)
    app.use(ElMain)
    app.use(ElMenu)
    app.use(ElMenuItem)
    app.use(ElMenuItemGroup)
    app.use(ElSubmenu)
    app.use(ElSelect)
    app.use(ElOption)
    app.use(ElDropdown)
    app.use(ElDropdownMenu)
    app.use(ElDropdownItem)
    app.use(ElBreadcrumb)
    app.use(ElBreadcrumbItem)
    app.use(ElCard)
    app.use(ElRow)
    app.use(ElCol)
    app.use(ElTable)
    app.use(ElTableColumn)
    app.use(ElSwitch)
    app.use(ElTooltip)
    app.use(ElPagination)
    app.use(ElTabs)
    app.use(ElTabPane)
    app.use(ElPopconfirm)
    app.use(ElProgress)
    app.use(ElConfigProvider)
    app.use(ElDialog)
    app.use(ElUpload)
    app.use(ElTag)
    app.use(ElTree)
    app.use(ElCheckbox)
    app.use(ElRadio)
    app.use(ElRadioGroup)
    
    app.config.globalProperties.$message = ElMessage
}