import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import md5 from "js-md5"
import zhCn from "element-plus/lib/locale/lang/zh-cn";

// 导入全局样式表
import './assets/css/global.css'
// import './assets/js/flexible.js'
import axios from 'axios'
import { ElMessageBox } from 'element-plus'


const app = createApp(App)
installElementPlus(app)
app.use(store).use(router)
app.use(installElementPlus, {
  locale: zhCn,
})

axios.defaults.baseURL = 'https://www.gcguanli.com/java/'
    // axios.defaults.baseURL = 'http://localhost:8080/'


// 请求拦截器
axios.interceptors.request.use(config => {
    config.headers["Content-type"] = "application/x-www-form-urlencoded";
    config.headers["satoken"] = window.sessionStorage.getItem('satoken');
    return config;
}, error => {
    return Promise.reject(error)
})

app.config.globalProperties.$http = axios
app.config.globalProperties.$confirm = ElMessageBox.confirm;
app.config.globalProperties.$md5 = md5

app.mount('#app');
