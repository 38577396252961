import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
const adver = ()=>import('../views/adver.vue')
const adver1 = ()=>import('../views/adver1.vue')
const login = ()=>import('../views/Login.vue')
const register = ()=>import('../views/Register.vue')
const main = ()=>import('../views/Main.vue')
const company = ()=>import('../views/company/Company.vue')
const item = ()=>import('../views/item/Item.vue')
const itemMember = ()=>import('../views/item/ItemMember.vue')
const user = ()=>import('../views/user/User.vue')
const power = ()=>import('../views/power/Power.vue')
const reportExcel = ()=>import('../views/report/ReportExcel.vue')
const report = ()=>import('../views/report/Report.vue')
const reportRecord = ()=>import('../views/report/ReportRecord.vue')
const review = ()=>import('../views/review/Review.vue')
const reviewRecord = ()=>import('../views/review/ReviewRecord.vue')
const reviewExcel = ()=>import('../views/review/ReviewExcel.vue')
const showAll = ()=>import('../views/show/ShowAll.vue')
const showPart = ()=>import('../views/show/ShowPart.vue')
const own = ()=>import('../views/own/Own.vue')
const reportPic = ()=>import('../views/report/ReportPic.vue')
const routes = [
    { path: '/', redirect: '/adver' },
    {
        path: '/adver',
        component: adver,
        meta: {
            isLogin: false
        }
    },
    {
        path: '/login',
        component: login,
        meta: {
            isLogin: false
        }
    },
    {
        path: '/register',
        component:register,
        meta: {
            isLogin: false
        }
    },
    {
        path: '/adver1',
        component: adver1,
        meta: {
            isLogin: true
        }
    },
    {
        path: '/main',
        component: main,
        name: 'main',
        meta: {
            isLogin: true
        },
        children: [{
            path: '',
            redirect: '/showAll'
        }, {
            // 公司
            path: '/company',
            component:company
        }, {
            // 项目
            path: '/item',
            component: item
        }, {
            path: '/itemMember',
            component: itemMember
        }, {
            // 用户
            path: '/user',
            component: user
        }, {
            // 权限
            path: '/power',
            component: power
        }, {
            // 表格
            path: '/reportExcel',
            component: reportExcel
        }, {
            // 填报
            path: '/report',
            component: report
        }, {
            // 测试
            path: '/reportPic',
            component: reportPic
        }, {
            path: '/reportRecord',
            component: reportRecord
        }, {
            // 审核
            path: '/review',
            component: review
        }, {
            path: '/reviewRecord',
            component: reviewRecord
        }, {
            path: '/reviewExcel',
            component: reviewExcel
        }, {
            // 展示
            name: 'showAll',
            path: '/showAll',
            component: showAll
        }, {
            name: 'showPart',
            path: '/showPart',
            component: showPart
        }, {
            // 个人
            path: '/own',
            component: own
        }],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        meta: {
            isLogin: false
        },
        component: () =>
            import ('../views/404.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作
// 前置守卫，判断用户是否登录
router.beforeEach((to, from, next) => {
    let path = window.location.href;
    let curHost = path.substring(0, path.indexOf("#"));
    let realHost = window.location.protocol + "//" + window.location.host + "/"
    if (curHost != realHost) { //#号前的地址不正确
        window.location.href = realHost + "#" + to.path //跳转到正确地址
    }
    const token = window.sessionStorage.getItem('satoken');
    const localToken = window.localStorage.getItem('satoken');
    const roleName = window.sessionStorage.getItem("roleName");
    if (to.matched.some(m => m.meta.isLogin)) {
        if (token || localToken) {
            next();
        } else {
            next({ path: '/adver' })
        }
    } else {
        if (token || localToken) {
            next({ path: '/adver1' });
        } else {
            next()
        }
    }
})

export default router
